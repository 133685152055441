// eslint-disable-next-line import/no-webpack-loader-syntax
import workerBase from 'workerize-loader!../workers/evaluate-condition';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { StringShim } from '@packages/helpers/core/shims/string-shim';
import { interpolateRegex } from '../regex';

const worker = workerBase();
/**
 *
 * @param {string} condition - stringified display condition from admin
 * @param {object} userState - state, including spreaded userAttributes and aktivity = statusData.plainStatuses
 * @return {Promise<boolean|*>}
 */

// You must wrap strings in admin console into ``
// `${goal.propertyPrise}` >= 1500000
// `${goal.location}` !== `London`
export const evaluateCondition = async (condition, userState) => {
  if (!StringShim.isString(condition)) {
    return Boolean(condition);
  }

  try {
    const expression = condition.replace(interpolateRegex, (_, name) => {
      return ObjectShim.getNested(userState, name);
    });

    return await worker.evaluate(expression);
  } catch {
    return false;
  }
};
