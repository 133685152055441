export const buildAddress = ({ location, dictionary }) => {
  if (location) {
    return (
      dictionary?.items?.reduce((address, { key, separator = '' }) => {
        const value = location[key];

        if (value) {
          return address + value + separator;
        }

        return address;
      }, '') ?? ''
    );
  }

  return '';
};

export const mapAddressAsSelectItem = ({ address, dictionary }) => {
  return (
    address && {
      id: address.Uprn,
      label: buildAddress({ location: address, dictionary }),
      value: address
    }
  );
};
