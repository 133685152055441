import { get, post } from './utils';

export const getUserPollsAnswers = async () => get('/user-polls');
export const getPoll = async ({ pollKey }) => get(`/polls/${pollKey}`);
export const savePollAnswer = async ({ pollKey, data = {} }) => post(`/polls/${pollKey}`, data);

export const polls = {
  getUserPollsAnswers,
  getPoll,
  savePollAnswer
};
