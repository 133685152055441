export const normalizeUserPollsAnswers = polls =>
  polls.reduce((acc, poll) => {
    const { key, value } = poll;

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(value);

    return acc;
  }, {});
