const flatArrayRecursively = (target, depth) => {
  return target.reduce((acc, current) => {
    if (depth > 0 && Array.isArray(current)) {
      return [...acc, ...flatArrayRecursively(current, depth - 1)];
    }

    return [...acc, current];
  }, []);
};

const flat = <T>(target: (T | T[])[] = [], depth = 1, native = true): (T | T[])[] => {
  if (native && Object.prototype.hasOwnProperty.call(Array.prototype, 'flat')) {
    return Array.prototype.flat.call(target, depth) as (T | T[])[];
  }

  return flatArrayRecursively(target, depth);
};

const normalize = <T extends Record<PropertyKey, any>, K extends keyof T>(
  target: T[] = [],
  key: K,
  mapper?: (item: T) => Partial<T>
): Record<K, T> => {
  return target.reduce((result, item) => {
    if (Object.prototype.hasOwnProperty.call(item, key)) {
      return {
        ...result,
        [item[key]]: typeof mapper === 'function' ? mapper(item) : item
      };
    }

    return result;
  }, {} as Record<K, T>);
};

const range = (from: number, to?: number): number[] => {
  return new Array(to ? to - from : from).fill(void 0).map((_, i) => (to ? from + i : i));
};

const take = <T>(target: T[] = [], count?: number): T[] => {
  return count ? target.slice(0, count) : target;
};

const intersection = <T>(arr1: T[] = [], arr2: T[] = []): T[] => {
  return arr1.filter(element => arr2.includes(element));
};

export const sumByKey = <T>(arr: T[] = [], key: string): number => arr.reduce((a, b) => a + (b[key] || 0), 0);

export const chunk = <T>(arr: T[], size: number): T[][] =>
  Array.from({ length: Math.ceil(arr.length / size) }, (_, i) => arr.slice(i * size, i * size + size));

export class ArrayShim {
  static flat = flat;
  static normalize = normalize;
  static range = range;
  static take = take;
  static intersection = intersection;
  static sumByKey = sumByKey;
  static chunk = chunk;
}
