import React from 'react';
import { connect } from 'react-redux';
import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  mapProps,
  withStateHandlers,
  branch,
  renderNothing
} from 'recompose';
import { isValid as isValidPostCode } from 'postcode';
import { isUndefined } from '@packages/helpers/core/common';
import { PromiseShim } from '@packages/helpers/core/shims/promise-shim';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { helpers } from '../../../api/helpers';
import { getPropertyByUprnCode } from '../../../store/reducers/user-attributes/actions';
import { parsePropertyIdFromPathname } from '../../../helpers/property-portfolio';
import { mapAddressAsSelectItem } from '../../../helpers/property-portfolio/property-address';
import { Slot } from '../../../components/slots';
import { InputGroup } from '../../../components/inputs/input-group';
import { InputField as Input } from '../../../components/inputs/input';
import { InputSelect as Select } from '../../../components/inputs/select/v2-select';
import { InputButtonWithIcon as ButtonWithIcon } from '../../../components/buttons/button-with-icon';
import { Spinner, withLoadingHandlers } from '../../../components/with-loader';
import { PurifiedHtmlContainer } from '../../../components/layout/purified-html-container';
import { ConfigService } from '../../../services/config-service';

const STAGES = {
  DEFAULT: 0,
  VALID_POST_CODE: 1,
  ADDRESS_LOOKUP: 2,
  READY: 3,
  PROCESSING: 4,
  DONE: 5
};

const DEFAULT_ERROR = 'Something wrong happens, try again...';
const MIN_DELAY = 1500;

const INPUT_POSTCODE_NAME = 'postCode';
const INPUT_ADDRESS_NAME = 'address';

const AddressSelect = compose(
  withState('items', 'setItems', []),
  lifecycle({
    async componentDidMount() {
      const { postCode, setItems, onLookUp, errorMessages, dictionary } = this.props;

      if (postCode) {
        onLookUp(true, STAGES.ADDRESS_LOOKUP);

        try {
          const response = await PromiseShim.unless(helpers.getPropertiesByPostcode(postCode), MIN_DELAY);

          if (response.ok) {
            const { results } = await response.json();

            if (results.length < 1) {
              return onLookUp(false, STAGES.DEFAULT, {
                key: 'postCode',
                value: errorMessages?.noAddresses
              });
            }

            const items = results.map(({ fullInfo }) => mapAddressAsSelectItem({ address: fullInfo, dictionary }));

            setItems(items);
            onLookUp(false, STAGES.READY);
          } else {
            throw new Error();
          }
        } catch (error) {
          onLookUp(false, STAGES.DEFAULT);
        }
      }
    },
    componentDidUpdate(prevProps) {
      const { items, value, onChange } = this.props;
      const isValueValid = items.some(item => item.id === value?.id);

      //if value is not in items, set it to empty
      if (prevProps.items !== items && value && !isValueValid) {
        onChange(INPUT_ADDRESS_NAME)('');
      }
    }
  }),
  branch(({ items }) => items.length < 1, renderNothing)
)(Select);

const LoadingMessage = ({ message }) => {
  return (
    message && (
      <Slot name={`append-${window.location.pathname}-coach-message`}>
        <PurifiedHtmlContainer renderAs='p' html={message} />
      </Slot>
    )
  );
};

const Component = React.memo(
  ({
    data = {},
    messages = {},
    stage,
    state,
    errors,
    errorMessages,
    disabled,
    loading,
    processing,
    handleAddressChange,
    handlePostCodeChange,
    onSubmit,
    dictionary
  }) => {
    const { postcode = {}, address = {} } = data;

    return (
      <>
        {stage === STAGES.ADDRESS_LOOKUP && <LoadingMessage message={messages.addressLookup} />}
        {stage === STAGES.PROCESSING && <LoadingMessage message={messages.processing} />}
        {loading && <Spinner />}
        <InputGroup heir>
          <Input
            type='text'
            name={INPUT_POSTCODE_NAME}
            label={postcode.label}
            value={state.postCode}
            format='upper'
            error={errors.postCode}
            placeholder={postcode.placeholder}
            onChange={handlePostCodeChange}
            disabled={loading}
          />
          {stage >= STAGES.VALID_POST_CODE && (
            <AddressSelect
              name={INPUT_ADDRESS_NAME}
              label={address.label}
              value={state.address}
              error={errors.address}
              errorMessages={errorMessages}
              postCode={state.postCode}
              placeholder={address.placeholder}
              disabled={loading}
              onChange={handleAddressChange}
              onLookUp={processing}
              dictionary={dictionary}
            />
          )}
          <ButtonWithIcon buttonType='submit' disabled={disabled || loading} onClick={onSubmit}>
            Continue
          </ButtonWithIcon>
        </InputGroup>
      </>
    );
  }
);

const mapStateToProps = ({ userAttributes, staticData }, { location }) => {
  const propertyId = parsePropertyIdFromPathname(location.pathname);
  const property = userAttributes.property.items[propertyId];

  const path = ConfigService.get('PROPERTY_PORTFOLIO.ADDRESS.DATA_DICTIONARY');

  const dictionary = ObjectShim.getNested(staticData, path);

  return {
    propertyId,
    postCode: property?.postCode,
    address: property?.address,
    prevUprn: property?.uprn,
    isPropertyReady: property?.isReady,
    dictionary
  };
};

export const QuestionPropertyAddressInput = compose(
  connect(mapStateToProps, { getPropertyByUprnCode }),
  withState('state', 'setState', ({ postCode, address, dictionary }) => {
    return {
      postCode,
      address: mapAddressAsSelectItem({ address, dictionary })
    };
  }),
  withState('stage', 'setStage', ({ state: { postCode } }) => {
    if (postCode) {
      return STAGES.VALID_POST_CODE;
    }

    return STAGES.DEFAULT;
  }),
  withStateHandlers(
    ({ state: { postCode }, errorMessages }) => ({
      errors: {
        postCode: postCode && isValidPostCode(postCode) ? null : errorMessages?.postcode ?? DEFAULT_ERROR,
        address: null
      }
    }),
    {
      setError:
        ({ errors }) =>
        (key, value) => ({
          errors: {
            ...errors,
            [key]: isUndefined(value) ? DEFAULT_ERROR : value
          }
        })
    }
  ),
  withLoadingHandlers(false),
  withHandlers({
    processing:
      ({ setLoading, setStage, setError }) =>
      (loading, stage, error) => {
        if (error) {
          setError(error.key, error.value);
        }

        setLoading(loading);

        if (isFinite(stage)) {
          setStage(stage);
        }
      }
  }),
  withHandlers({
    handlePostCodeChange:
      ({ setState, stage, setStage, setError, errorMessages }) =>
      value => {
        setState(state => ({ ...state, postCode: value }));
        setError('postCode', isValidPostCode(value) ? null : errorMessages?.postcode);

        if (stage > STAGES.DEFAULT) {
          setStage(STAGES.DEFAULT);
        }
      },
    handleAddressChange:
      ({ setState, stage, setStage }) =>
      () =>
      value => {
        setState(state => ({ ...state, address: value }));

        if (stage >= STAGES.READY) {
          setStage(STAGES.VALID_POST_CODE);
        }
      },
    submitPostCode:
      ({ data, state, sendUserAttribute, processing }) =>
      async () => {
        const postCodeAttributeField = data?.postcode?.userAttributeField;
        const staticPayload = data?.staticPayload;

        if (postCodeAttributeField) {
          const nextValue = { [postCodeAttributeField]: state.postCode };

          if (state.address) {
            const addressAttributeField = data?.address?.userAttributeField;

            if (addressAttributeField) {
              nextValue[addressAttributeField] = null;
            }
          }

          processing(true);
          await sendUserAttribute({ ...nextValue, ...staticPayload });
          processing(false, STAGES.VALID_POST_CODE);
        }
      },
    submitAddress:
      ({
        data,
        state,
        propertyId,
        sendUserAttribute,
        getPropertyByUprnCode,
        processing,
        errorMessages,
        prevUprn,
        isPropertyReady,
        attributeType
      }) =>
      async () => {
        const addressAttributeField = data?.address?.userAttributeField;
        const staticPayload = data?.staticPayload;

        if (addressAttributeField) {
          processing(true, STAGES.PROCESSING);

          const value = state.address.value;

          try {
            const submit = async () => {
              const sendRequest = sendUserAttribute(attributeType);
              const response = await sendRequest({ [addressAttributeField]: value, ...staticPayload });

              if (response.errorMsg) {
                throw new Error();
              }
              if (prevUprn && prevUprn === value.Uprn && isPropertyReady) return;
              return await getPropertyByUprnCode({ uprn: value.Uprn, addressKey: value.ADDRESS_KEY, propertyId });
            };

            await PromiseShim.unless(submit(), MIN_DELAY);

            processing(false, STAGES.DONE, {
              key: 'address',
              value: null
            });
          } catch {
            processing(false, STAGES.VALID_POST_CODE, {
              key: 'address',
              value: errorMessages?.address
            });
          }
        }
      }
  }),
  mapProps(({ submitPostCode, submitAddress, goToNextQuestion, ...props }) => {
    let onSubmit, disabled;

    const { stage, state, errors } = props;

    switch (stage) {
      case STAGES.DEFAULT: {
        onSubmit = submitPostCode;
        disabled = !!errors.postCode;
        break;
      }
      case STAGES.VALID_POST_CODE:
      case STAGES.READY: {
        onSubmit = submitAddress;
        disabled = !state.address;
        break;
      }
      case STAGES.DONE: {
        onSubmit = goToNextQuestion;
        disabled = false;
        break;
      }
      default:
        break;
    }

    return {
      ...props,
      onSubmit,
      disabled
    };
  })
)(Component);
