import { connect } from 'react-redux';
import { compose, withState, withHandlers, lifecycle, branch, renderNothing } from 'recompose';
import { evaluateCondition } from '../../helpers/checklist/display-condition';

const mapStateToProps = ({ userAttributes, statusData: { plainStatuses }, points }) => ({
  userAttributes,
  plainStatuses,
  userData: { ...userAttributes?.user, ...points }
});

export const withDisplayCondition = compose(
  connect(mapStateToProps),
  withState('isPreventedToBeRender', 'setRenderCondition', ({ displayCondition }) => Boolean(displayCondition)),
  withHandlers(() => {
    let unmounted = false;

    return {
      evaluateDisplayCondition:
        ({ setRenderCondition }) =>
        async (displayCondition, userState) => {
          const isAllowedToBeRender = await evaluateCondition(displayCondition, userState);

          if (!unmounted) {
            setRenderCondition(!isAllowedToBeRender);
          }
        },
      unmount: () => () => {
        unmounted = true;
      }
    };
  }),
  lifecycle({
    componentDidMount() {
      const { userAttributes, plainStatuses, displayCondition, evaluateDisplayCondition, userData } = this.props;

      if (displayCondition) {
        evaluateDisplayCondition(displayCondition, { ...userAttributes, activity: plainStatuses, user: userData });
      }
    },
    componentDidUpdate(prevProps) {
      const { displayCondition, plainStatuses, userAttributes, evaluateDisplayCondition, userData } = this.props;

      if (displayCondition && userAttributes !== prevProps.userAttributes) {
        evaluateDisplayCondition(displayCondition, { ...userAttributes, activity: plainStatuses, user: userData });
      }
    },
    componentWillUnmount() {
      const { unmount } = this.props;

      // notify handlers to stop throwing state changes
      // if the component had been already unmounted
      unmount();
    }
  }),
  branch(({ isPreventedToBeRender }) => isPreventedToBeRender, renderNothing)
);
