import { AffiliateIcon } from '@apps/firsthomecoach/src/components/icons/affiliate-icon';
import { PurifiedHtmlContainer } from '@apps/firsthomecoach/src/components/layout/purified-html-container';
import { SafeArea } from '@apps/firsthomecoach/src/components/layout/safe-area';
import { ModalPortal } from '@apps/firsthomecoach/src/components/modal';
import { toggleModal } from '@apps/firsthomecoach/src/store/reducers/modals';
import { useClickOutside } from '@packages/hooks/core/use-click-outside';
import { Dimmer } from '@packages/ui-kit/core/dimmer';
import { ScrollContainer } from '@packages/ui-kit/core/scroll-container';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styles from '../../styles/components/overlay.module.scss';
import { TYPE_TO_ACTIVITY_KEY } from '../../helpers/content-interaction/constants';
import { sendContentStatus } from '../../store/reducers/content-interaction';

const Overlay = () => {
  const dispatch = useDispatch();
  const display = useSelector(state => state.modals.showOverlay);
  const { videoUrl, ownKey } = useSelector(state => state.modals.params);
  const onClose = () => dispatch(toggleModal(false, 'overlay', {}));

  useEffect(() => {
    if (display) {
      const options = {
        activityKey: TYPE_TO_ACTIVITY_KEY.video,
        activityStatusKey: 'seen',
        data: {
          key: ownKey
        }
      };
      dispatch(sendContentStatus(options));
    }
  }, [dispatch, display, ownKey]);

  const [anchor, handleClickOutside] = useClickOutside(onClose);

  if (!display) return null;

  return (
    <ModalPortal>
      <Dimmer on={display} display='flex' onClick={handleClickOutside} onEscClick={onClose}>
        <CSSTransition in={display} timeout={200} classNames={{ ...styles }} exit={false} appear>
          <ScrollContainer disabled={display}>
            <div className={styles.videoOverlay}>
              <SafeArea className={styles.safeArea} edges={['bottom']}>
                <div ref={anchor} className={styles.videoContainer}>
                  <div>
                    <div>
                      <PurifiedHtmlContainer
                        parse
                        html={videoUrl}
                        sanitizer={{
                          ADD_TAGS: ['iframe'],
                          ADD_ATTR: ['allow', 'height', 'width', 'allowfullscreen', 'frameborder']
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.videoClose}>
                  <AffiliateIcon name='nav-exit' className={styles.videoCloseIcon} />
                </div>
              </SafeArea>
            </div>
          </ScrollContainer>
        </CSSTransition>
      </Dimmer>
    </ModalPortal>
  );
};

export default Overlay;
