import { interpolateRegex, starsRegex } from './regex';
import { wrap } from './formatters/string-format';

export const insertVarIntoString = (template, data, wrapper) => {
  return template.replace(/\n/g, '<br />').replace(interpolateRegex, (_, name) => wrap(data[name], wrapper) ?? '');
};

export const insertVarBeforeInterpolation = (template, data) => {
  return template.replace(starsRegex, (_, name) => data[name] ?? '');
};
