import { lifecycle } from 'recompose';
import { PromiseShim } from '@packages/helpers/core/shims/promise-shim';
import { UserService } from '../../../services/user-service';
import { ConfigService } from '../../../services/config-service';
import { TRACKING_STATUSES } from '../../../helpers/constants';

export const withDefaultContent = lifecycle({
  async componentDidMount() {
    const { getPoints, getRewardCards, turnOffLoading, sendTrackingData, getUserPollsData } = this.props;
    const { POINTS, REWARDS } = ConfigService.get('DISPLAY_MODULES', {});

    if (!UserService.hasAccount()) {
      const promises = [];

      promises.push(getUserPollsData());

      if (POINTS) {
        promises.push(getPoints());

        if (REWARDS) {
          promises.push(getRewardCards());
        }
      }

      await PromiseShim.allSettled(promises);
    }

    turnOffLoading();
    sendTrackingData(TRACKING_STATUSES.APP_RENDER);

    // eslint-disable-next-line no-console
    console.log('[HBC] All required data is loaded', new Date());
  }
});
