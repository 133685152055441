import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { compose } from 'recompose';
import { usePopper } from '@packages/hooks/core/use-popper';
import { sameWidth } from '@packages/ui-kit/core/popper/same-width';
import { offset } from '@packages/ui-kit/core/popper/offset';
import { useMenuList } from '@packages/ui-kit/core/menu-list';

// TODO: merge after V2 release.
import styles from '../../../styles/components/select.module.scss';
import listStyles from '../../../styles/components/input-list-popover.module.scss';

import { AffiliateIcon } from '../../icons/affiliate-icon';
import { Spinner } from '../../with-loader';
import { withAnchor, withContainer, withError, withExplainer, withLabel } from '../input-field';

const topOffset = {
  ...offset,
  options: {
    offset: ({ reference }) => {
      return [0, -reference.height];
    }
  }
};

// TODO: Move it.
const PopoverPortal = ({ children }) => ReactDOM.createPortal(children, document.getElementById('popover'));

export const Select = ({ id, name, items = [], value, placeholder, disabled, loading, onChange }) => {
  const handleEdit = item => {
    if (onChange) {
      onChange(name)(item);
    }
  };

  const { visible, open, close, click, change, isFocused, isSelected, MenuList, MenuListItem } = useMenuList({
    items,
    value,
    onChange: handleEdit
  });

  const { onPopoverRef, onTriggerRef } = usePopper({ placement: 'bottom', modifiers: [topOffset, sameWidth] }, visible);

  const handleClick = event => {
    if (!loading && !disabled) open(event);
  };

  return (
    <>
      <div className={listStyles.container}>
        <button
          ref={onTriggerRef}
          id={id ?? name}
          className={styles.field}
          disabled={loading || disabled}
          onClick={handleClick}
          onBlur={close}
          onKeyDown={change}
        >
          {loading ? (
            <Spinner />
          ) : (
            <>
              <span className={styles.value}>{value?.label ?? placeholder}</span>
              <AffiliateIcon className={styles.icon} name='select-indicator' inline />
            </>
          )}
        </button>
      </div>
      <PopoverPortal>
        <div ref={onPopoverRef}>
          {visible && (
            <MenuList className={classnames(listStyles.items, listStyles.menu)}>
              {items.map((item, index) => {
                const { id, label, disabled } = item;

                const selected = isSelected(item);

                return (
                  <MenuListItem
                    key={id}
                    index={index}
                    className={listStyles.item}
                    selected={selected}
                    focused={isFocused(index)}
                    disabled={disabled}
                    onClick={click(item)}
                  >
                    <span className={listStyles.value}>{label}</span>
                    {selected && <AffiliateIcon name='select-value' className={listStyles.icon} inline />}
                  </MenuListItem>
                );
              })}
            </MenuList>
          )}
        </div>
      </PopoverPortal>
    </>
  );
};
export const withSelectLabel = withLabel({ className: styles.label, layout: styles.container });
export const InputSelect = compose(withAnchor, withContainer, withExplainer, withError, withSelectLabel)(Select);
