import React from 'react';
import classnames from 'classnames';
import { compose, withState, withHandlers, defaultProps, lifecycle } from 'recompose';
import { isNil } from '@packages/helpers/core/common';
import styles from '../../styles/components/segmented-button.module.scss';
import { valueToActiveState } from '../../helpers/inputs/segmented-button';
import { withContainer } from '../inputs/input-field';

const SegmentComponent = React.memo(({ active, handleClick, item: { label, value, name, additionalValues } }) => (
  <button
    className={classnames(styles.segment, { [styles.active]: value === active })}
    onClick={handleClick({ value, name, label, additionalValues })}
  >
    <span className={styles.label}>{label}</span>
  </button>
));

const Segment = lifecycle({
  componentDidMount() {
    const { active, item, onChange } = this.props;
    const { name, additionalValues, value, label } = item;

    // Add additional data to already checked radio
    if (onChange && active === value && additionalValues) {
      onChange({ value, name, label, additionalValues });
    }
  }
})(SegmentComponent);

const SegmentedButtonComponent = React.memo(({ items, className, atid, ...props }) => (
  <div className={classnames(styles.container, className)} data-atid={atid}>
    {items.map(item => (
      <Segment key={item.label} item={item} {...props} />
    ))}
  </div>
));

const withSegmentedInputLabel =
  Component =>
  ({ label, ...props }) =>
    (
      <div className={styles.wrapper}>
        {label && <span className={styles['input-label']}>{label}</span>}
        <Component {...props} />
      </div>
    );

export const SegmentedButton = compose(
  defaultProps({
    items: []
  }),
  withState('active', 'setActive', ({ items, initialValue, isInput, value, plainValue }) => {
    if (isInput) {
      return valueToActiveState(value, plainValue);
    }
    return isNil(initialValue) ? items[0] && items[0].value : initialValue;
  }),
  withHandlers({
    handleClick:
      ({ setActive, onChange }) =>
      ({ value, name, label, additionalValues }) =>
      () => {
        setActive(value);
        onChange && onChange({ value, name, label, additionalValues });
      }
  })
)(SegmentedButtonComponent);

export const InputSegmentedButton = compose(withContainer, withSegmentedInputLabel)(SegmentedButton);
