import React from 'react';
import { compose, withPropsOnChange } from 'recompose';
import PropTypes from 'prop-types';
import styles from '../../../styles/widgets/digital-coach-widget/poll-bars.module.scss';
import { getMaxValueByKey, sumByKey } from '../../../helpers/digital-coach/simple-poll';
import PollBarRow from './poll-bar';

export const PollOptionsContainer = ({ children }) => <div className={styles.container}>{children}</div>;
const Component = ({ data, maxCount, totalCount }) => (
  <PollOptionsContainer>
    {data
      .sort((a, b) => b.count - a.count)
      .map(({ value, count, label }) => (
        <PollBarRow
          key={value}
          label={label ?? value}
          count={Number(count)}
          maxCount={maxCount}
          totalCount={totalCount}
        />
      ))}
  </PollOptionsContainer>
);

const PollBars = compose(
  withPropsOnChange({}, ({ data }) => ({
    totalCount: sumByKey(data, 'count'),
    maxCount: getMaxValueByKey(data, 'count')
  }))
)(Component);

const PollBarOptionPropTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired
};

PollBars.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(PollBarOptionPropTypes))
};

export { PollBars, PollBarOptionPropTypes };
