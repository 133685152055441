import { createAction, createReducer } from '../utils';
import { normalizeUserPollsAnswers } from '../../helpers/polls';

const GET_POLL_REQUEST = 'GET_POLL_REQUEST';
const GET_POLL_SUCCESS = 'GET_POLL_SUCCESS';
const GET_POLL_FAILURE = 'GET_POLL_FAILURE';

const GET_USER_POLLS_DATA_REQUEST = 'GET_USER_POLLS_DATA_REQUEST';
const GET_USER_POLLS_DATA_SUCCESS = 'GET_USER_POLLS_DATA_SUCCESS';
const GET_USER_POLLS_DATA_FAILURE = 'GET_USER_POLLS_DATA_FAILURE';

const POST_POLL_ANSWER_REQUEST = 'POST_POLL_ANSWER_REQUEST';
const POST_POLL_ANSWER_SUCCESS = 'POST_POLL_ANSWER_SUCCESS';
const POST_POLL_ANSWER_FAILURE = 'POST_POLL_ANSWER_FAILURE';

/**
 * @param {object} params
 * @param {string} params.pollKey
 */

export const getPollByKey = ({ pollKey }) =>
  createAction(
    {
      request: GET_POLL_REQUEST,
      success: GET_POLL_SUCCESS,
      failure: GET_POLL_FAILURE
    },
    api => api.polls.getPoll
  )({ pollKey });

export const getUserPollsData = () =>
  createAction(
    {
      request: GET_USER_POLLS_DATA_REQUEST,
      success: GET_USER_POLLS_DATA_SUCCESS,
      failure: GET_USER_POLLS_DATA_FAILURE
    },
    api => api.polls.getUserPollsAnswers
  )();
/**
 * @typedef {Object} SavePollAnswerData
 * @property {array<string>} pollOptions - list of selected options
 */

/**
 * @param {object} params
 * @param {string} params.pollKey
 * @param {SavePollAnswerData} params.data - data to be sent to the server
 */
export const postPollAnswer = ({ pollKey, data }) =>
  createAction(
    {
      request: POST_POLL_ANSWER_REQUEST,
      success: POST_POLL_ANSWER_SUCCESS,
      failure: POST_POLL_ANSWER_FAILURE
    },
    api => api.polls.savePollAnswer
  )({ pollKey, data });

const initialState = {
  pollsList: {},
  loading: {},
  loadingOnAnswer: {},
  userPollsData: {},
  loadingUserPollsData: true,
  error: null
};

export const polls = createReducer(initialState, {
  [GET_POLL_REQUEST]: (state, action) => ({
    ...state,
    loading: { ...state.loading, [action.pollKey]: true },
    error: null
  }),
  [GET_POLL_SUCCESS]: (state, action) => ({
    ...state,
    pollsList: { ...state.pollsList, [action.params.pollKey]: action.payload },
    loading: { ...state.loading, [action.params.pollKey]: false },
    error: null
  }),
  [GET_POLL_FAILURE]: (state, action) => ({
    ...state,
    loading: { ...state.loading, [action.id]: false },
    error: action.error
  }),
  [GET_USER_POLLS_DATA_REQUEST]: state => ({ ...state, loadingUserPollsData: true }),
  [GET_USER_POLLS_DATA_SUCCESS]: (state, action) => ({
    ...state,
    userPollsData: { ...state.userPollsData, ...normalizeUserPollsAnswers(action.payload) },
    loadingUserPollsData: false,
    error: null
  }),
  [GET_USER_POLLS_DATA_FAILURE]: (state, action) => ({
    ...state,
    loadingUserPollsData: false,
    error: action.error
  }),
  [POST_POLL_ANSWER_REQUEST]: (state, action) => ({
    ...state,
    loadingOnAnswer: { ...state.loadingOnAnswer, [action.params.pollKey]: true }
  }),
  [POST_POLL_ANSWER_SUCCESS]: (state, action) => ({
    ...state,
    userPollsData: { ...state.userPollsData, [action.params.pollKey]: action.params.data.pollOptions },
    loadingOnAnswer: { ...state.loadingOnAnswer, [action.params.pollKey]: false },
    error: null
  }),
  [POST_POLL_ANSWER_FAILURE]: (state, action) => ({
    ...state,
    loadingOnAnswer: { ...state.loadingOnAnswer, [action.params.pollKey]: false },
    error: action.error
  })
});
